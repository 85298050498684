@import "_custom-properties.scss";
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.site-footer-row {
    display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr) );;
	&.site-footer-row-columns-2 {
		&.site-footer-row-column-layout-right-golden {
			grid-template-columns: 1fr 2fr;
		}
		&.site-footer-row-column-layout-left-golden {
			grid-template-columns: 2fr 1fr;
		}
	}
	&.site-footer-row-columns-3 {
		grid-template-columns: 1fr 1fr 1fr;
		&.site-footer-row-column-layout-left-half {
			grid-template-columns: 2fr 1fr 1fr;
		}
		&.site-footer-row-column-layout-right-half {
			grid-template-columns: 1fr 1fr 2fr;
		}
		&.site-footer-row-column-layout-center-half {
			grid-template-columns: 1fr 2fr 1fr;
		}
		&.site-footer-row-column-layout-center-wide {
			grid-template-columns: 2fr 6fr 2fr;
		}
	}
	&.site-footer-row-columns-4 {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		&.site-footer-row-column-layout-left-forty {
			grid-template-columns: 2fr 1fr 1fr 1fr;
		}
		&.site-footer-row-column-layout-right-forty {
			grid-template-columns: 1fr 1fr 1fr 2fr;
		}
	}
	&.site-footer-row-columns-5 {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
	&.site-footer-row-columns-1 {
		display: flex;
		justify-content: center;
		.site-footer-section-center {
			flex-grow: 1;
		}
		.site-footer-section {
			flex: 1;
			text-align: center;
			min-width: 0;
		}
	}
}
.site-footer-row-layout-fullwidth > .site-footer-row-container-inner > .site-container {
    max-width:none;
}
.footer-img{
img {
	display: inline-block;
	vertical-align: middle;
	margin-inline-end: 10px;
}
}
@media screen and (min-width: $before-tablet-plus-query) and (max-width: $tablet-minus-query) {
	.site-footer-row-tablet-layout-contained {
		padding: 0 1.5rem;
	}
	.site-footer-row-tablet-layout-fullwidth, .site-footer-row-tablet-layout-standard {
		padding: 0;
	}
	.site-footer-row-container-inner .site-footer-row {
		&.site-footer-row-tablet-column-layout-default, &.site-footer-row-tablet-column-layout-row {
			grid-template-columns: repeat(1,minmax(0,1fr));
			&.ft-ro-collapse-rtl {
				.site-footer-section:nth-child(1) {
					order:6;
				}
				.site-footer-section:nth-child(2) {
					order:5;
				}
				.site-footer-section:nth-child(3) {
					order:4;
				}
				.site-footer-section:nth-child(4) {
					order:3;
				}
				.site-footer-section:nth-child(5) {
					order:2;
				}
				.site-footer-section:nth-child(6) {
					order:1;
				}
			}
		}
		&.site-footer-row-columns-2 {
			&.site-footer-row-tablet-column-layout-equal {
				grid-template-columns:repeat(2, minmax(0, 1fr) );
			}
			&.site-footer-row-tablet-column-layout-right-golden {
				grid-template-columns: 1fr 2fr;
			}
			&.site-footer-row-tablet-column-layout-left-golden {
				grid-template-columns: 2fr 1fr;
			}
		}
		&.site-footer-row-columns-3 {
			&.site-footer-row-tablet-column-layout-equal {
				grid-template-columns: repeat(3, minmax(0, 1fr) );
			}
			&.site-footer-row-tablet-column-layout-left-half {
				grid-template-columns: 2fr 1fr 1fr;
			}
			&.site-footer-row-tablet-column-layout-right-half {
				grid-template-columns: 1fr 1fr 2fr;
			}
			&.site-footer-row-tablet-column-layout-center-half {
				grid-template-columns: 1fr 2fr 1fr;
			}
			&.site-footer-row-tablet-column-layout-center-wide {
				grid-template-columns: 2fr 6fr 2fr;
			}
			&.site-footer-row-tablet-column-layout-equal {
				grid-template-columns: repeat(3, minmax(0, 1fr) );
			}
			&.site-footer-row-tablet-column-layout-first-row {
				grid-template-columns: 1fr 1fr;
				>*:first-child {
					grid-column: 1 / -1;
				}
			}
			&.site-footer-row-tablet-column-layout-last-row {
				grid-template-columns: 1fr 1fr;
				>*:last-child {
					grid-column: 1 / -1;
				}
			}
		}
		&.site-footer-row-columns-4 {
			&.site-footer-row-tablet-column-layout-equal {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
			&.site-footer-row-tablet-column-layout-two-grid {
				grid-template-columns: repeat(2, minmax(0, 1fr) );;
			}
		}
		&.site-footer-row-columns-5 {
			&.site-footer-row-tablet-column-layout-equal {
				grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			}
		}
	}
}
@media screen and (max-width: $phone-minus-query) {
	.site-footer-row-mobile-layout-contained {
		padding: 0 1.5rem;
	}
	.site-footer-row-container-inner .site-footer-row {
		&.site-footer-row-mobile-column-layout-default, &.site-footer-row-mobile-column-layout-row {
			grid-template-columns:repeat(1,minmax(0,1fr));
			&.ft-ro-collapse-rtl {
				.site-footer-section:nth-child(1) {
					order:6;
				}
				.site-footer-section:nth-child(2) {
					order:5;
				}
				.site-footer-section:nth-child(3) {
					order:4;
				}
				.site-footer-section:nth-child(4) {
					order:3;
				}
				.site-footer-section:nth-child(5) {
					order:2;
				}
				.site-footer-section:nth-child(6) {
					order:1;
				}
			}
		}
		&.site-footer-row-columns-2 {
			&.site-footer-row-mobile-column-layout-equal {
				grid-template-columns: repeat(2, minmax(0, 1fr) );;
			}
			&.site-footer-row-mobile-column-layout-right-golden {
				grid-template-columns: 1fr 2fr;
			}
			&.site-footer-row-mobile-column-layout-left-golden {
				grid-template-columns: 2fr 1fr;
			}
		}
		&.site-footer-row-columns-3 {
			&.site-footer-row-mobile-column-layout-equal {
				grid-template-columns: repeat(3, minmax(0, 1fr) );
			}
			&.site-footer-row-mobile-column-layout-left-half {
				grid-template-columns: 2fr 1fr 1fr;
			}
			&.site-footer-row-mobile-column-layout-right-half {
				grid-template-columns: 1fr 1fr 2fr;
			}
			&.site-footer-row-mobile-column-layout-center-half {
				grid-template-columns: 1fr 2fr 1fr;
			}
			&.site-footer-row-mobile-column-layout-center-wide {
				grid-template-columns: 2fr 6fr 2fr;
			}
			&.site-footer-row-mobile-column-layout-equal {
				grid-template-columns: repeat(3, minmax(0, 1fr) );
			}
			&.site-footer-row-mobile-column-layout-first-row {
				grid-template-columns: repeat(2, minmax(0, 1fr) );;
				>*:first-child {
					grid-column: 1 / -1;
				}
			}
			&.site-footer-row-mobile-column-layout-last-row {
				grid-template-columns:repeat(2, minmax(0, 1fr) );;
				>*:last-child {
					grid-column: 1 / -1;
				}
			}
		}
		&.site-footer-row-columns-4 {
			&.site-footer-row-mobile-column-layout-equal {
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
			&.site-footer-row-mobile-column-layout-two-grid {
				grid-template-columns: repeat(2, minmax(0, 1fr) );;
			}
		}
		&.site-footer-row-columns-5 {
			&.site-footer-row-mobile-column-layout-equal {
				grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			}
		}
	}
	.site-footer-row-mobile-layout-fullwidth, .site-footer-row-mobile-layout-standard {
		padding: 0;
	}
}
.site-footer-section {
	display: grid;
	max-height: inherit;
}
.site-footer-item {
    display: flex;
	align-items: center;
	margin-inline-end: 10px;
	max-height: inherit;
}
.site-footer-section > .site-footer-item:last-child {
    margin-inline-end: 0;
}
.footer-widget-area {
	flex: 1;
	min-width: 0;
	display: flex;
}
.ft-ro-dir-row .footer-section-inner-items-2 .footer-widget-area {
	flex: unset;
    margin-inline-start: 30px;
}
.ft-ro-dir-row .footer-section-inner-items-2 .footer-widget-area:first-child {
    margin-inline-start: 0;
}
.footer-widget-area > * {
	flex: 1;
	min-width: 0;
}
.footer-widget-area.content-valign-middle {
    align-items: center;
}
.footer-widget-area.content-valign-top {
    align-items: flex-start;
}
.footer-widget-area.content-valign-bottom {
    align-items: flex-end;
}
.footer-widget-area.content-align-center {
	text-align: center;
	.footer-navigation {
		justify-content: center;
	}
	.widget_media_image img {
		margin: 0 auto;
	}
}
.footer-widget-area.content-align-right {
	text-align: right;
	.footer-navigation {
		justify-content: flex-end;
		.menu {
			justify-content: flex-end;
		}
	}
	.widget_media_image img {
		margin: 0 0 0 auto;
	}
}
.footer-widget-area.content-align-left {
	text-align: left;
	.footer-navigation {
		justify-content: flex-start;
		.menu {
			justify-content: flex-start;
		}
	}
}
@media screen and (max-width: $tablet-minus-query) {
	.ft-ro-dir-row .footer-section-inner-items-2 .footer-widget-area.content-tablet-align-center:first-child {
		margin-inline-start: auto;
	}
	.ft-ro-dir-row .footer-section-inner-items-2 .footer-widget-area.content-tablet-align-center:first-child ~ .footer-widget-area:last-child {
		margin-inline-end: auto;
	}
	.footer-widget-area.content-tablet-align-center {
		text-align: center;
		.footer-navigation {
			justify-content: center;
			.menu {
				justify-content: center;
			}
		}
		.widget_media_image img {
			margin: 0 auto;
		}
	}
	.footer-widget-area.content-tablet-align-right {
		text-align: right;
		.footer-navigation {
			justify-content: flex-end;
			.menu {
				justify-content: flex-end;
			}
		}
		.widget_media_image img {
			margin: 0 0 0 auto;
		}
	}
	.footer-widget-area.content-tablet-align-left {
		text-align: left;
		.footer-navigation {
			justify-content: flex-start;
			.menu {
				justify-content: flex-start;
			}
		}
	}
}
@media screen and (max-width: $phone-minus-query) {
	.ft-ro-m-dir-row .footer-section-inner-items-2 .footer-widget-area.content-mobile-align-center:first-child {
		margin-inline-start: auto;
	}
	.ft-ro-m-dir-row .footer-section-inner-items-2 .footer-widget-area.content-mobile-align-center:first-child ~ .footer-widget-area:last-child {
		margin-inline-end: auto;
	}
	.footer-widget-area.content-mobile-align-center {
		text-align: center;
		.footer-navigation {
			justify-content: center;
			.menu {
				justify-content: center;
			}
		}
		.widget_media_image img {
			margin: 0 auto;
		}
	}
	.footer-widget-area.content-mobile-align-right {
		text-align: right;
		.footer-navigation {
			justify-content: flex-end;
			.menu {
				justify-content: flex-end;
			}
		}
		.widget_media_image img {
			margin: 0 0 0 auto;
		}
	}
	.footer-widget-area.content-mobile-align-left {
		text-align: left;
		.footer-navigation {
			justify-content: flex-start;
			.menu {
				justify-content: flex-start;
			}
		}
	}
}
.ft-ro-dir-column .site-footer-section {
	flex-direction: column;
}
@media screen and (max-width: $tablet-minus-query) {
	.ft-ro-t-dir-column .site-footer-section {
		flex-direction: column;
	}
	.ft-ro-t-dir-row .site-footer-section {
		flex-direction: row;
	}
}
@media screen and (max-width: $phone-minus-query) {
	.ft-ro-m-dir-column .site-footer-section {
		flex-direction: column;
	}
	.ft-ro-m-dir-row .site-footer-section {
		flex-direction: row;
	}
}
// Footer Navigation.
.footer-navigation, .footer-menu-container {
	display: flex;
}
.footer-navigation {
	.menu {
			li {
				padding: 0;
				a {
					position: relative;
					display: block;
					width: 100%;
					text-decoration: none;
					color: var(--global-palette4);
					transition: all 0.2s ease-in-out;
					padding: 0.1em 0.5em;
					&:hover {
						color: var(--global-palette-highlight);
					}
					&:focus {
						color: var(--global-palette-highlight);
					}
					&:after {
						background: var(--global-gray-500);
						width: 1px;
						height: 12px;
						position: absolute;
						content: "";
						right: 0;
						top: 0;
						bottom: 0;
						margin: auto;
					}
				}
				&:first-child {
					a { 
						padding-inline-start: 0;
					}	
				}				
				&:last-child {
					a { 
						padding-inline-end: 0;
						&:after {
							content: none;
						}
					}
				}
			}
		display: block;
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.footer-widget-area.footer-navigation-layout-stretch-true .footer-menu-container {
    width: 100%;
}
.footer-widget-area.footer-navigation-layout-stretch-true .footer-navigation .menu {
    width: 100%;
    justify-content: space-around;
}
.site-footer-wrap .footer-navigation .footer-menu-container > ul li a:hover {
	text-decoration: none;
}
.site-footer-wrap .footer-social-wrap .social-button{
    color: var(--global-palette4);
    background: var(--global-palette7);
	&:hover {
		text-decoration: none;
		color: var(--global-palette-btn);
		background: var(--global-palette-btn-bg);
	}
}
.ft-ro-lstyle-normal a:not(.button) {
    text-decoration: underline;
}
.ft-ro-lstyle-noline a:not(.button) {
    text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}
.site-footer-section {
	position: relative;
	&:not(:last-child):after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		height: auto;
		width: 0;
		right: -15px;
		transform: translateX(50%);
	}
}
.footer-html {
	p:last-child {
		margin-block-end:0;	
	}
	p:first-child {
		margin-block-start:0;
	}
}